<template>
  <div class="cms-block">
    <template v-if="blockComponents">
      <cms-api-component
        v-for="(blockComponent, i) in blockComponents"
        :key="i"
        :class="{'--shown': isVisible, '--hidden': !isVisible}"
        :component-class="blockComponent.props.class"
        :component-is="blockComponent.component"
        v-bind="blockComponent.props"
        v-on="eventListeners"
        :value="value['v-model'] ? getVariable(value['v-model']) : (blockComponent.props ? blockComponent.props.value : undefined)"
        @input="value['v-model'] ? setVariable(value['v-model'], $event) : undefined"
        @hook:mounted="onBlockMounted(blockComponent)"
        @hook:destroyed="onBlockDestroyed(blockComponent)"
        ref="refBlock"
      ></cms-api-component>
    </template>
  </div>
</template>

<script>
import {
  interpolate,
  validate,
  execute,
  getProperty,
} from '@/modules/cms/functions';

import CmsApiComponent from '@/modules/cms/components/Api/Component.vue';

export default {
  name: 'cms-block',

  components: { CmsApiComponent },

  props: {
    value: {
      type: Object,
      required: true,
    },

    data: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    showHidden: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      block: null,
    };
  },

  computed: {
    eventListeners() {
      if (!this.block['v-on']) {
        return null;
      }

      let listeners = {};
      for (let eventName in this.block['v-on']) {
        if (!this.block['v-on'].hasOwnProperty(eventName)) {
          continue;
        }
        listeners[eventName] = ($event) =>
          this.runAction(eventName, $event, this.block['v-on'][eventName]);
      }
      return listeners;
    },

    isVisible() {
      if (!this.block || !this.block['v-if']) {
        return true;
      }

      try {
        return validate(this.data, this.block['v-if']);
      } catch (validationError) {
        return false;
      }
    },

    blockComponents() {
      if (!this.block.component) {
        return null;
      }

      if (!this.showHidden && !this.isVisible) {
        return null;
      }

      if (this.block['v-for']) {
        let iterationArray = this.getVariable(this.block['v-for']);
        if (!Array.isArray(iterationArray)) {
          if (this.showHidden) {
            return [Object.assign({}, this.block)];
          }

          return null;
        }

        let retval = [];
        iterationArray.forEach((item) => {
          let itemProps = {};
          if (this.block.props) {
            itemProps = interpolate(
              this.block.props,
              Object.assign({}, this.data, { item: item })
            );
          }

          retval.push(Object.assign({}, this.block, { props: itemProps }));
        });

        return retval;
      }

      let props = {};
      if (this.block.props) {
        props = interpolate(this.block.props, this.data);
      }

      return [Object.assign({}, this.block, { props })];
    },
  },

  methods: {
    async runAction(eventName, $event, configuredAction) {
      let actions = Array.isArray(configuredAction)
        ? configuredAction
        : [configuredAction];

      for (let i = 0; i < actions.length; i++) {
        let action = interpolate(
          actions[i],
          Object.assign({}, this.data, { $block: this.block, $event }),
          // true
          false
        );

        // Caso especial, page-event
        if (action.action == 'page-event') {
          this.$emit('page-event', {
            event: action.event,
            arguments: action.arguments,
          });
          continue;
        }

        try {
          let retval = await execute(action, this);
          if (action['v-model']) {
            this.setVariable(action['v-model'], retval);
          }
        } catch (err) {
          console.warn('Block.vue@runAction', err);
        }
      }
    },

    getVariable(propertyName) {
      return getProperty(this.data, propertyName);
    },

    setVariable(propertyName, value) {
      let curvar = this.data;
      let parts = propertyName.split('.');
      let len = parts.length;

      for (let i = 0; i < len - 1; i++) {
        if (typeof curvar[parts[i]] == 'undefined' || !curvar[parts[i]]) {
          this.$set(curvar, parts[i], {});
        }
        curvar = curvar[parts[i]];
      }

      this.$set(curvar, parts[len - 1], value);

      this.$emit('update:data', this.data);
    },

    onBlockMounted(block) {
      if (block.mounted) {
        this.runAction('mounted', null, block.mounted);
      }

      if (block.ref) {
        this.$emit('ref:mounted', {
          name: block.ref,
          component: this.$refs.refBlock[0],
        });
      }
    },

    onBlockDestroyed(block) {
      if (block.destroyed) {
        this.runAction('destroyed', null, block.destroyed);
      }

      if (block.ref) {
        this.$emit('ref:destroyed', {
          name: block.ref,
          component: this.$refs.refBlock[0],
        });
      }
    },
  },

  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (!newValue) {
          this.block = {};
          return;
        }

        this.block = JSON.parse(JSON.stringify(newValue));
      },
    },
  },
};
</script>

<style lang="scss">
.cms-block {
  & > .--hidden {
    opacity: 0.5;
  }
}
</style>
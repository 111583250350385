import Vue from 'vue';
Vue.component('CmsMediaHtml', () => import('@/modules/cms/components/Media/Html/Html.vue'));
Vue.component('CmsMediaHtmlEditor', () => import('@/modules/cms/components/Media/Html/HtmlEditor.vue'));
Vue.component('CmsMediaHtmlEditorToolbar', () => import('@/modules/cms/components/Media/Html/HtmlEditorToolbar.vue'));

export default {
  "id": "CmsMediaHtml",
  "title": "Texto",
  "icon": "g:text_fields",

  "block": {
    "component": "CmsMediaHtml",
    "props": {
      "value": "<h1>Título</h1><p>Escribe aquí</p>"
    }
  },

  "editor": {
    "face": {
      "title": "HTML",
      "component": "CmsMediaHtmlEditor",
      "v-model": "block.props.value",
      "props": {
        "theme": "{{ block.props.theme }}"
      }
    },

    "toolbar": {
      "component": "CmsMediaHtmlEditorToolbar"
    },

    "actions": [
      {
        "title": "Apariencia",
        "component": "UiFormGenerator",
        "v-model": "block",
        "props": {
          "schema": {
            "fields": [
              {
                "type": "select",
                "label": "Theme",
                "model": "props.theme",
                "options": [
                  { "text": 'Predeterminado', "value": null },
                  { "text": 'EyeBurN3r', "value": "eyeburner" }
                ]
              }
            ]
          }
        }
      }
    ]
  }
};
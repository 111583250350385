export default (httpClient) => ({

  getFileList(path) {
    return httpClient.get(path);
  },

  deleteFile(path, fileId) {
    return httpClient.delete(path+'/'+fileId);
  }

})
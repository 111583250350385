import Vue from 'vue';
Vue.component('CmsInput', () => import('@/modules/ui/components/UiInput/UiInput.vue'));
Vue.component('CmsInputSettings', () => import('@/modules/cms/components/Input/InputSettings.vue'));

export default {
  "tags": ["input"],

  "id": "CmsInputFile",
  "title": "Archivo",
  "icon": "mdi:paperclip",

  "block": {
    "component": "CmsInput",
    "v-model": "files",
    "props": {
      "type": "file",
      "path": "/1/cms/people/{{ person.id }}/pages/{{ page.id }}/files"
    }
  },

  "editor": {
    "actions": [
      {
        "title": "Archivo",
        "component": "CmsInputSettings",
        "v-model": "block"
      },

      {
        "title": "Validación",
        "component": "UiFormGenerator",
        "v-model": "block",
        "props": {
          "schema": {
            "fields": [
              {
                "type": "checkbox",
                "label": "Obligatorio",
                "model": "props.required"
              },
              {
                "type": "text",
                "label": "Error message",
                "model": "props.errorMessage"
              }
            ]
          }
        }
      },

      {
        "title": "Datos",
        "component": "UiFormGenerator",
        "v-model": "block",
        "props": {
          "schema": {
            "fields": [
              {
                "type": "text",
                "label": "Variable",
                "model": "v-model"
              }
            ]
          }
        }
      },

    ]
  }
};
import Vue from 'vue';

Vue.component('CmsMediaDocumentViewer', () => import('@/modules/cms/components/Media/Document/Viewer.vue'));
Vue.component('CmsMediaDocumentPicker', () => import('@/modules/cms/components/Media/Document/Picker.vue'));

export default {
  "id": "CmsMediaDocumentViewer",
  "title": "Documento embebido",
  "icon": "mdi:file-word",

  "block": {
    "component": "CmsMediaDocumentViewer",
    "props": {
      "value": "",
      "provider": "google"
    }
  },

  "editor": {
    "face": {
      "component": "CmsMediaDocumentPicker",
      "v-model": "block.props",
      "props": {
        "path": "/1/cms/pages/{{ page.id }}/files"
      }
    }
  }
};
import Vue from 'vue';
Vue.component('CmsMediaGallery', () => import('@/modules/cms/components/Media/Gallery/Gallery.vue'));
Vue.component('CmsMediaGalleryEditor', () => import('@/modules/cms/components/Media/Gallery/GalleryEditor.vue'));

export default {
  "id": "CmsMediaGallery",
  "title": "Galería",
  "icon": "mdi:image-multiple",

  "block": {
    "component": "CmsMediaGallery",
    "props": {
      "files": [],
      "view": "grid",
      "previewLimit": 4
    }
  },

  "editor": {
    "actions": [
      {
        "title": "Galería",
        "component": "CmsMediaGalleryEditor",
        "v-model": "block.props",
        "props": {
          "path": "/1/cms/pages/{{ page.id }}/files"
        }
      }
    ]
  }
};
import Vue from 'vue';

import { UiFormGenerator } from '@/modules/ui/components';
Vue.component('UiFormGenerator', UiFormGenerator);

Vue.component('CmsMediaFile', () => import('@/modules/cms/components/Media/File/File.vue'));
Vue.component('CmsMediaFileEditor', () => import('@/modules/cms/components/Media/File/FileEditor.vue'));
Vue.component('CmsMediaFileLauncher', () => import('@/modules/cms/components/Media/File/FileLauncher.vue'));

export default {
  "id": "CmsMediaFile",
  "title": "Archivo",
  "icon": "mdi:file",
  "description": "Archivo",

  "block": {
    "component": "CmsMediaFile",
    "props": {
      "href": "",
      "text": "",
      "secondary": "",
      "name": "",
      "size": "",
      "mimetype": ""
    }
  },

  "editor": {
    "face": {
      "component": "CmsMediaFileEditor",
      "v-model": "block.props"
    },

    "actions": [
      {
        "title": "Detalles",
        "component": "UiFormGenerator",
        "v-model": "block",
        "props": {
          "schema": {
            "fields": [
              {
                "type": "text",
                "label": "Título",
                "model": "props.text"
              },
              {
                "type": "text",
                "label": "Descripción",
                "model": "props.secondary"
              },
              {
                "type": "static",
                "label": "Nombre",
                "model": "props.name"
              },
              {
                "type": "static",
                "label": "Tamaño",
                "model": "props.size"
              },
              {
                "type": "static",
                "label": "Tipo",
                "model": "props.mimetype"
              }
            ]
          }
        }
      }
    ]
  },

  "launcher": {
    "component": "CmsMediaFileLauncher",
    "props": {
      "path": "/1/cms/pages/{{ page.id }}/files"
    }
  }
};
import Vue from 'vue';
Vue.component('CmsMediaLink', () => import('@/modules/cms/components/Media/Link/Link.vue'));
Vue.component('CmsMediaLinkEditor', () => import('@/modules/cms/components/Media/Link/LinkEditor.vue'));

export default {
  "id": "CmsMediaLink",
  "title": "Link",
  "icon": "mdi:open-in-new",
  "description": "Vínculo a página externa",

  "block": {
    "component": "CmsMediaLink",
    "props": {
      "href": "",
      "text": "",
      "secondary": ""
    }
  },

  "editor": {
    "face": {
      "component": "CmsMediaLinkEditor",
      "v-model": "block.props"
    }
  }
};
import Vue from 'vue';
import { UiFormGenerator } from '@/modules/ui/components';
Vue.component('UiFormGenerator', UiFormGenerator);
Vue.component('CmsMediaImage', () => import('@/modules/cms/components/Media/Image/Image.vue'));
Vue.component('CmsMediaImagePicker', () => import('@/modules/cms/components/Media/Image/ImagePicker.vue'));

import UrlPicker from '@/modules/filesystem/components/UrlPicker/UrlPicker.vue';
Vue.component('UrlPicker', UrlPicker);

export default {
  "id": "CmsMediaImage",
  "title": "Imagen",
  "icon": "g:image",

  "block": {
    "component": "CmsMediaImage",
    "props": {
      "src": ""
    }
  },

  "editor": {
    "face": {
      "component": "CmsMediaImagePicker",
      "v-model": "block.props.src",
      "props": {
        "path": "/1/cms/pages/{{ page.id }}/files"
      }
    },

    "actions": [
      {
        "title": "URL",
        "component": "UiFormGenerator",
        "v-model": "block.props",
        "props": {
          "schema": {
            "fields": [
              {
                "model": "src",
                "type": "text",
                "label": "URL de la imágen"
              },

              {
                "model": "href",
                "type": "text",
                "label": "Vínculo",
                "props": {
                  "placeholder": "http://"
                }
              }
            ]
          }
        }
      },

      // {
      //   "title": "Explorador de archivos",
      //   "component": "UrlPicker",
      //   "v-model": "block.props.src",
      //   "props": {
      //     "path": "/1/cms/pages/{{ page.id }}/files"
      //   }
      // }

    ]
  }
};
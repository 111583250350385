<template>
  <div class="filesystem-manager">
    <slot
      name="default"
      :files="files"
      :is-loading="isLoading"
      :deleteFile="deleteFile"
      :appendFiles="appendFiles"
    ></slot>
  </div>
</template>

<script>
import useApi from "@/modules/api/mixins/useApi";
import apiFiles from "@/modules/filesystem/api/files";

export default {
  name: "filesystem-manager",
  mixins: [useApi],
  api: apiFiles,

  props: {
    path: {
      type: String,
      required: true
    }
  },

  watch: {
    path: {
      immediate: true,
      handler() {
        this.reload();
      }
    }
  },

  data() {
    return {
      files: [],
      isLoading: false
    };
  },

  methods: {
    async reload() {
      if (!this.path) {
        this.files = [];
        return;
      }

      this.isLoading = true;
      this.files = await this.$api.getFileList(this.path);
      this.isLoading = false;
      this.$emit("fetched", this.files);
    },

    deleteFile(file) {
      if (!confirm('Eliminar este archivo?')) {
        return;
      }

      this.files = this.files.filter(f => f.endpoint != file.endpoint); // remove from files array
      this.$api.deleteFile(this.path, file.endpoint);
    },

    appendFiles(files) {
      files.forEach(incomingFile => {
        if (this.files.findIndex(f => f.endpoint == incomingFile.endpoint) == -1)  {
          this.files.push(incomingFile);
        }
      });
    }
  }
};
</script>
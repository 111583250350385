import Vue from 'vue';

Vue.component('CmsMediaMath', () => import('@/modules/cms/components/Media/Math/Katex.vue'));
Vue.component('MediaMathPicker', () => import('@/modules/cms/components/Media/Math/Picker.vue'));

export default {
  "tags": ["academia"],

  "id": "CmsMediaMath",
  "title": "Fórmula",
  "icon": "mdi:variable",

  "block": {
    "component": "CmsMediaMath",
    "props": {
      "value": "x = \\frac { -b \\pm \\sqrt{ b^2-4ac } } { 2a }"
    }
  },

  "editor": {
    "face": {
      "component": "MediaMathPicker",
      "v-model": "block.props.value"
    }
  }
};
<template>
  <div
    class="cms-component"
    :class="{'--error': !!error}"
  >
    <!-- https://stackoverflow.com/questions/43658481/passing-props-dynamically-to-dynamic-component-in-vuejs -->
    <component
      ref="innerComponent"
      v-if="isReady"
      :is="componentIs"
      :class="componentClass"
      v-bind="$attrs"
      v-on="$listeners"
      @hook:mounted="onComponentMounted"
    >
      <slot></slot>
    </component>

    <span v-if="!isReady && !error">... {{ componentIs }} ...</span>
    <span v-if="error">
      '{{ componentIs }}' error:
      <span>{{ error }}</span>
    </span>
  </div>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'cms-component',

  props: {
    componentIs: {
      type: String,
      required: true,
    },

    componentClass: {
      type: String,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      // baseUrl:
      //   this.$store && this.$store.state.url
      //     ? `${this.$store.state.url}/1/cms/dist`
      //     : null,

      isComponentLoaded: false,
      error: null,
      library: null,
    };
  },

  computed: {
    isReady() {
      return this.isComponentLoaded;
    },
  },

  async created() {
    // Look for already declared components
    if (
      Vue.options.components[this.componentIs] != undefined ||
      this.componentIs.toLowerCase() == 'div'
    ) {
      this.isComponentLoaded = true;
      return;
    }

    // Look for "source" property in block.  Assume it's a umd module URL
    // if (this.source) {
    //   try {
    //     let importedComponent = await this.importLibrary(
    //       this.source,
    //       this.name
    //     );

    //     Vue.component(this.name, importedComponent);
    //     this.isComponentLoaded = true;
    //     return;
    //   } catch (err) {
    //     // oh well, carry on then
    //   }
    // }

    // if (!this.baseUrl) {
    //   return;
    // }

    // // Look for component in base library
    // this.library = await this.importLibrary(
    //   this.baseUrl + '/cmslib/CmsLib.umd.min.js',
    //   'CmsLib'
    // );

    // if (this.library && this.library.components[this.name]) {
    //   Vue.component(this.name, this.library.components[this.name]);
    //   this.isComponentLoaded = true;
    //   return;
    // }

    // // Look for individual component library
    // try {
    //   let importedComponent = await this.importLibrary(
    //     `${this.baseUrl}/components/${this.name}/${this.name}.umd.min.js`,
    //     this.name
    //   );

    //   this.importStyle(
    //     `${this.baseUrl}/components/${this.name}/${this.name}.css`
    //   );

    //   Vue.component(this.name, importedComponent);
    //   this.isComponentLoaded = true;
    // } catch (err) {
    //   this.error = err;
    // }
  },

  methods: {
    onComponentMounted($event) {
      this.$emit('load', $event);
    },

    importLibrary(src, targetName) {
      return new Promise((resolve, reject) => {
        if (typeof window[targetName] !== 'undefined') {
          resolve(window[targetName]);
        }

        let node = document.createElement('script'),
          okHandler,
          errHandler;

        node.src = src;

        okHandler = ($event) => {
          node.removeEventListener('load', okHandler);
          node.removeEventListener('error', errHandler);

          if (typeof window[targetName] == 'undefined') {
            reject(`Could not load library '${targetName}'`);
          }
          resolve(window[targetName]);
        };

        errHandler = () => {
          node.removeEventListener('load', okHandler);
          node.removeEventListener('error', errHandler);
          reject(`Error fetching '${node.src}'`);
        };

        node.addEventListener('load', okHandler);
        node.addEventListener('error', errHandler);

        document.body.appendChild(node);
      });
    },

    importStyle(href) {
      let style = document.createElement('link');
      style.href = href;
      style.type = 'text/css';
      style.rel = 'stylesheet';
      document.getElementsByTagName('head')[0].append(style);
    },
  },
};
</script>

<style lang="scss">
.cms-component {
  &.--error {
    border: 2px dashed #ccc;
    border-radius: 2px;
    padding: 24px;
    background-color: rgba(0, 0, 0, 0.02);
  }
}
</style>
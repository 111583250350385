import Vue from 'vue';
Vue.component('V3PersonRelativeManager', () => import('@/modules/v3/components/V3PersonRelativeManager/V3PersonRelativeManager.vue'));
Vue.component('BlockFace', () => import('./BlockFace.vue'));

export default {
  "disabled": false,
  "tags": ["esign"],

  "id": "V3PersonRelativeManager",
  "title": "Administrar familiares",
  "icon": "mdi:account-group",

  "block": {
    "component": "V3PersonRelativeManager",
    "props": {
      "personId": ""
    }
  },

  "editor": {
    "face": {
      "component": "BlockFace"
    },
  }
  // "editor": {
  //   "face": {
  //     "component": "EsignConsentEditor",
  //     "v-model": "block.props"
  //   },

  //   "toolbar": {
  //     "component": "CmsMediaHtmlEditorToolbar"
  //   },
  // }
};
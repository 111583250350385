import Vue from 'vue';
Vue.component('CommunicationThreadChat', () => import('@/modules/communication/components/Thread/Chat.vue'));


export default {
  "disabled": true,
  "tags": ["communication"],
  "id": "CommunicationThreadChat",
  "title": "Foro",
  "icon": "mdi:forum",

  "block": {
    "component": "CommunicationThreadChat",
    "props": {
      "threadId": "5ea9c69625267",
      // "threadId": "{{ page.id }}",
      "personId": "17925"
    }
  },

  "editor": {
    "actions": [
      {
        "title": "Foro",
        "component": "UiFormGenerator",
        "v-model": "block.props",
        "props": {
          "schema": {
            "fields": [
              {
                "type": "text",
                "label": "ID de thread",
                "model": "threadId"
              },

              {
                "type": "text",
                "label": "ID de persona",
                "model": "personId"
              }
            ]
          }
        }
      }
    ]
  }
};